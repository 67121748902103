<template>
  <div id="payment-element">
    <h2>Make Payment</h2>
    <div class="block tabular" style="padding: 20px; position: relative;">
      <p v-if="!paymentComplete">
        Add credit to your account. Enter the amount below then continue to
        securely enter your card details
      </p>

      <div v-if="paymentComplete">
        <b-alert variant="success" show>
          <strong>Payment Complete</strong>
          <div>
            We have received your payment and are updating your balance
          </div>
          <div style="margin-top: 10px;">
            <b-button
              class="action"
              size="sm"
              @click="resetPayment"
              variant="success"
              >Make further payment</b-button
            >
          </div>
        </b-alert>
      </div>

      <div v-if="!stripeElementsSecret && !paymentComplete">
        <b-input-group size="lg" prepend="£">
          <b-form-input
            placeholder="Amount"
            v-model="requestAmount"
            type="number"
            step="0.01"
            min="0.01"
            max="500.00"
          ></b-form-input>
        </b-input-group>

        <div v-if="isDebt" class="debt-options">
          <div>
            Please note: <span>{{ debtPercent }}&percnt;</span> of each top-up
            will be taken for debt recovery
          </div>

          <div class="button-option">
            <b-form-checkbox v-model="isFullDebt" switch
              >Apply full top-up amount to debt recovery
              balance</b-form-checkbox
            >
          </div>
        </div>

        <div class="payment-button">
          <b-button
            v-on:click="requestPayment"
            v-if="!paymentLoading"
            variant="outline-info"
            >Continue</b-button
          >
          <div v-if="paymentLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>

        <PaymentNotes></PaymentNotes>
      </div>

      <div v-if="stripeElementsSecret && !paymentComplete">
        <div class="card-styles" id="card-element"></div>

        <div v-if="isFullDebt" class="debt-options">
          <span>Note:</span> This top-up is set to apply in full to debt
          recovery collection.
        </div>

        <div class="payment-button">
          <b-button
            v-on:click="purchase"
            variant="outline-success"
            v-if="!paymentLoading"
            >Pay £{{ requestAmount }}</b-button
          >
          <div v-if="paymentLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faSpinner, faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import PaymentNotes from "@/views/Shared/PaymentNotes";

library.add(fas, faSpinner, faAlignLeft);

export default {
  name: "MakePayment",
  components: { PaymentNotes },
  props: {
    id: {
      type: String,
      required: true
    },
    debtPercent: {
      type: String
    }
  },
  mounted: function() {
    this.configureStripe();
  },
  computed: {
    isDebt() {
      return this.debtPercent && this.debtPercent > 0;
    }
  },
  data() {
    return {
      minAmount: 1,
      maxAmount: 500,
      isFullDebt: false,
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      stripe: "",
      elements: "",
      card: "",
      requestAmount: "",
      paymentLoading: false,
      stripeElementsSecret: "",
      paymentComplete: false
    };
  },
  methods: {
    resetPayment() {
      this.requestAmount = "";
      this.paymentComplete = false;
    },
    requestPayment() {
      this.paymentLoading = true;

      let amount = this.requestAmount;
      let errorReason = null;

      if (isNaN(amount)) {
        errorReason = "Please enter a valid payment amount";
      }

      if (
        !errorReason &&
        Math.floor(amount) !== amount &&
        amount.toString().split(".")[1] !== undefined &&
        amount.toString().split(".")[1].length > 2
      ) {
        errorReason = "Please enter a valid payment amount";
      }

      if (amount < this.minAmount) {
        errorReason = "The minimum top-up amount is £1.00";
      }

      if (!errorReason && amount > this.maxAmount) {
        errorReason = "The maximum payment is £500.00";
      }

      if (errorReason) {
        const title = "Payment Error";
        const subtitle = errorReason;
        const samePage = true;
        this.$store.dispatch("pushError", { title, subtitle, samePage });
        this.paymentLoading = false;
        return;
      }

      this.requestAmount = amount = parseFloat(amount).toFixed(2);

      this.$store
        .dispatch("requestPayment", {
          amount: amount,
          id: this.id,
          isFullDebt: this.isFullDebt
        })
        .then(response => {
          this.$gtag.event("payment", {
            event_category: "paymentRequestedPrePay",
            event_label: amount
          });
          this.stripeElementsSecret = response.data.ref;

          this.$nextTick(() => {
            this.card.mount("#card-element");
          });
        })
        .catch(err => {
          const title = "Payment Error";
          const subtitle = err;
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.paymentLoading = false;
        });
    },
    configureStripe() {
      this.stripe = window.Stripe(this.stripeAPIToken);

      let style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      this.elements = this.stripe.elements();
      this.card = this.elements.create("card", { style: style });
    },
    purchase() {
      this.paymentLoading = true;
      this.stripe
        .confirmCardPayment(this.stripeElementsSecret, {
          payment_method: {
            card: this.card
          }
        })
        .then(result => {
          if (result.error) {
            let gTagCategory = "paymentErrorPrePayCard";
            let eventLabel = result.error.message;
            let customerError = result.error.message;

            if (result.error.type !== "card_error") {
              gTagCategory = "paymentErrorPrePayApp";
              customerError =
                "We were unable to process this payment. Please contact us if this issue persists.";
            }

            if (result.error.type === "validation_error") {
              customerError = result.error.message;
            }

            if (
              result.error.payment_method &&
              result.error.payment_method.card.brand === "amex"
            ) {
              eventLabel = "amex";
              customerError =
                "We no longer accept American Express Payments. Please use an alternative payment method";
            }

            this.$gtag.event(result.error.type, {
              event_category: gTagCategory,
              event_label: eventLabel
            });

            const title = "Payment Error";
            const subtitle = customerError;
            const samePage = true;
            this.$store.dispatch("pushError", { title, subtitle, samePage });
          } else {
            if (result.paymentIntent.status === "succeeded") {
              this.$gtag.event("payment", {
                event_category: "paymentSuccessPrePay"
              });
              this.card.clear();
              this.stripeElementsSecret = null;
              this.paymentComplete = true;
              const title = "Payment Processing";
              const subtitle =
                "Your payment will appear on your dashboard shortly";
              const samePage = true;
              this.$store.dispatch("pushSuccess", {
                title,
                subtitle,
                samePage
              });
            }
          }
          this.paymentLoading = false;
        });
    },
    clearCard() {
      this.card.clear();
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/dashboard";
.card-styles {
  max-width: 600px;
  border: 1px solid #bbb;
  transition: box-shadow 0.2s ease, -webkit-box-shadow 0.2s ease;
  padding: 5px;
}

.debt-options {
  text-align: left;
  margin-top: 10px;
  font-size: 0.9rem;

  span {
    font-weight: bold;
  }

  .button-option {
    margin-top: 10px;
  }
}
</style>
